import { LoadingButton } from "@mui/lab";
import { Button, styled } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { EditorView } from "prosemirror-view";
import { LegacyRef, forwardRef, useRef, useState } from "react";

import InsertMediaButton from "components/feed/ActivityEditor/buttons/InsertMediaButton";
import Avatar from "components/shared/Avatar";
import InsertEmojiButton from "components/shared/inputs/Editor/buttons/InsertEmojiButton";
import InsertLinkButton from "components/shared/inputs/Editor/buttons/InsertLinkButton";
import InsertMentionButton from "components/shared/inputs/Editor/buttons/InsertMentionButton";
import LinkForm from "components/shared/inputs/Editor/forms/LinkForm";
import {
  clearAllContent,
  docHasContent,
  getContentAsMarkdown,
} from "components/shared/inputs/Editor/helpers";
import autoLink from "components/shared/inputs/Editor/plugins/autoLink";
import editLink from "components/shared/inputs/Editor/plugins/editLink";
import placeholder from "components/shared/inputs/Editor/plugins/placeholder";
import suggestions from "components/shared/inputs/Editor/plugins/suggestions";
import useEditor from "components/shared/inputs/Editor/useEditor";
import SocialEditor from "components/shared/inputs/SocialEditor";
import Snackbar from "components/shared/Snackbar";
import MediaAttachmentPreview, {
  MediaAttachmentData,
} from "components/ui/MediaAttachmentPreview";
import useMe from "hooks/useMe";
import { CommentInput } from "lib/motorcade/hooks/useComment";

const StyledSocialEditor = styled(SocialEditor)(({ theme }) => ({
  minWidth: "200px",
  flexGrow: 1,
  overflow: "auto",
  cursor: "text",
  "& .ProseMirror": {
    flex: 1,
    minHeight: "40px",
    padding: "0 12px",
    borderTopLeftRadius: theme.spacing(0.5),
    borderBottomLeftRadius: theme.spacing(0.5),

    "& .placeholder": {
      ...theme.typography.body2,
    },

    "& p": {
      lineHeight: "1.25",
      margin: "13px 0",
    },
  },
}));

type Props = {
  isSubmitting: boolean;
  onSubmit: (input: CommentInput) => Promise<void>;
  open: boolean;
  type: "comment" | "reply";
  initialData?: {
    body?: string;
    mediaType?: string;
    mediaUrl?: string;
  };
  onClose?: () => void;
};

const ActivityCardCommentInput = forwardRef(
  (
    { onSubmit, isSubmitting, open, type, initialData, onClose }: Props,
    ref: LegacyRef<HTMLDivElement>
  ) => {
    const editorAnchorRef = useRef<HTMLDivElement>();
    const editorRef = useRef<EditorView>();
    const snackbarRef = useRef(null);
    const [media, setMedia] = useState<MediaAttachmentData | null>(
      initialData?.mediaUrl && {
        url: initialData?.mediaUrl,
        type: initialData?.mediaType,
      }
    );

    const isEditing = !!initialData?.body;

    const [state, setState] = useEditor({
      content: initialData?.body || "",
      plugins: [
        autoLink(),
        editLink(),
        placeholder({
          text: `Add a ${type}...`,
        }),
        suggestions({ triggerCharacter: "@" }),
      ],
    });
    const me = useMe();

    const view = editorRef?.current?.view;
    const canSubmit = docHasContent(state.doc) || media?.file;

    const handleSetMediaAttachmentFile = (file) => {
      const isSizeValid = file.size / 1000000 <= 10;
      const isNameLengthValid = file.name.length <= 100;

      if (!isSizeValid) {
        return snackbarRef?.current?.callSnackbar(
          "File size must be less than 10MB",
          "error"
        );
      }
      if (!isNameLengthValid) {
        return snackbarRef?.current?.callSnackbar(
          "File name must be less than 100 characters",
          "error"
        );
      }
      setMedia({ file });
    };

    const resetInput = () => {
      const tr = clearAllContent(state);
      setState(state.apply(tr));
    };

    const handleSubmit = async () => {
      const markdown = getContentAsMarkdown(state.doc);
      if (!markdown) return;
      const mediaFile = media?.url || media?.file;
      let mediaType: string;
      if (
        media?.type?.includes("video") ||
        media?.file?.type?.includes("video")
      ) {
        mediaType = "video";
      } else if (
        media?.type?.includes("image") ||
        media?.file?.type?.includes("image")
      ) {
        mediaType = "image";
      }
      try {
        await onSubmit({ markdown, mediaFile, mediaType });
      } catch (e) {
        snackbarRef?.current?.callSnackbar(
          `We were unable to submit your ${type}. Please try again.`,
          "error"
        );
      }
      setMedia(null);
      resetInput();
    };

    if (!open) return null;

    return (
      <div ref={ref}>
        <Snackbar reference={snackbarRef} />

        <Stack gap={1}>
          <Box display="flex" gap={2}>
            <Avatar src={me.profile.avatar} />

            <Box
              ref={editorAnchorRef}
              borderRadius="8px"
              sx={(theme) => ({
                border: `1px solid ${theme.palette.text.secondary}`,

                [theme.breakpoints.only("xs")]: {
                  margin: 0,
                },
              })}
              width="100%"
            >
              <Box
                display="flex"
                flex={1}
                flexWrap="wrap"
                justifyContent="flex-end"
              >
                <StyledSocialEditor
                  ref={editorRef}
                  setState={setState}
                  state={state}
                />

                <LinkForm
                  anchorEl={editorAnchorRef.current}
                  setState={setState}
                  state={state}
                  view={view}
                />

                <Box
                  alignItems="flex-end"
                  display="flex"
                  justifyContent="space-between"
                  mt="auto"
                  width={(media?.file || media?.url) && "100%"}
                >
                  <MediaAttachmentPreview
                    attachmentData={media}
                    onDelete={() => setMedia(null)}
                  />

                  <Box
                    alignItems={media?.file ? "flex-end" : "center"}
                    display="flex"
                    height="100%"
                  >
                    <InsertLinkButton
                      setState={setState}
                      state={state}
                      view={view}
                    />

                    <InsertMediaButton
                      disabled={!!media?.file}
                      setMediaAttachmentFile={handleSetMediaAttachmentFile}
                    />

                    <InsertMentionButton
                      setState={setState}
                      state={state}
                      view={view}
                    />

                    <InsertEmojiButton
                      setState={setState}
                      state={state}
                      view={view}
                    />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box sx={{ alignSelf: "flex-end" }}>
            {isEditing && (
              <Button sx={{ mr: 2 }} variant="text" onClick={onClose}>
                Cancel
              </Button>
            )}

            <LoadingButton
              disabled={!canSubmit || isSubmitting}
              loading={isSubmitting}
              sx={{ textTransform: "capitalize" }}
              variant="contained"
              onClick={handleSubmit}
            >
              {isEditing ? "Save" : type}
            </LoadingButton>
          </Box>
        </Stack>
      </div>
    );
  }
);

export default ActivityCardCommentInput;

/* eslint-disable camelcase */
import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FeedAPIResponse } from "getstream";
import { cloneDeep } from "lodash";
import { useContext } from "react";

import { MediaAttachmentData } from "components/ui/MediaAttachmentPreview";
import useMe from "hooks/useMe";
import parseMentions from "lib/markdown/parseMentions";
import { NOTIFICATION_FEED_GROUP } from "lib/motorcade/constants";
import {
  FEED_COMMENT_CREATE,
  FEED_COMMENT_UPDATE,
} from "lib/motorcade/graphql/mutations";
import { StreamContext } from "lib/motorcade/providers/stream";
import {
  AlertCallback,
  CcoReaction,
  CommentReaction,
  FeedActivity,
} from "lib/motorcade/types";
import { findMyReaction } from "lib/motorcade/utils/finders";
import {
  removePendingReactions,
  removePendingResponses,
  updateFeedActivity,
} from "lib/motorcade/utils/state";
import { getReactionStub, getResponseStub } from "lib/motorcade/utils/stubs";

import { useFileUploader } from "./useFileUploader";

type Props = {
  activity: FeedActivity;
  comment?: CommentReaction;
  alertCallback: AlertCallback;
};

export type CommentInput = {
  markdown: string;
  mediaFile?: File | string;
  mediaType?: string;
};

export type FeedCommentUpdateInput = {
  commentId: string;
  markdown: string;
  attachment?: {
    url: string;
    mediaType: string;
  };
  mentionedUserIds?: string[];
};

export default function useComment({
  activity,
  comment,
  alertCallback,
}: Props) {
  const { streamClient, currentFeedQueryKey } = useContext(StreamContext);
  const queryClient = useQueryClient();
  const { uploadFile } = useFileUploader();
  const me = useMe();

  const [createComment] = useApolloMutation(FEED_COMMENT_CREATE);
  const [feedCommentUpdate] = useApolloMutation(FEED_COMMENT_UPDATE);

  const { mutateAsync: addComment, isLoading: addCommentLoading } = useMutation(
    {
      mutationKey: [currentFeedQueryKey],
      mutationFn: async (input: CommentInput) => {
        let attachment: MediaAttachmentData | undefined;
        if (input?.mediaFile instanceof File && input?.mediaFile) {
          attachment = await uploadFile(input.mediaFile);
        }

        const { data } = await createComment({
          variables: {
            input: {
              activityId: activity.id,
              markdown: input.markdown,
              mentionedUserIds: parseMentions(input.markdown),
              postType: activity.verb === "poll" ? "POLL" : "POST",
              attachment,
            },
          },
        });

        const newComment = await streamClient.reactions.get(
          data.feedCommentCreate.id
        );

        return { newComment };
      },
      onMutate: async (input: CommentInput) => {
        await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
        const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

        const pendingComment = getResponseStub({
          author: me,
          input,
          type: "comment",
        });

        const newData = {
          ...activity,
          reaction_counts: {
            ...activity.reaction_counts,
            comment: (activity.reaction_counts.comment || 0) + 1,
          },
          latest_reactions: {
            ...activity.latest_reactions,
            comment: [
              pendingComment,
              ...(activity.latest_reactions.comment || []),
            ],
          },
          own_reactions: {
            ...activity.own_reactions,
            comment: [
              pendingComment,
              ...(activity.own_reactions.comment || []),
            ],
          },
        };

        queryClient.setQueryData(
          [currentFeedQueryKey],
          (feed: FeedAPIResponse) =>
            updateFeedActivity({
              feed,
              activity,
              newData,
              queryKey: currentFeedQueryKey,
            })
        );

        return { prevFeed };
      },
      onSuccess: ({ newComment }) => {
        const pendingRemovedActivity = removePendingResponses({
          activity,
          type: "comment",
        });
        pendingRemovedActivity.latest_reactions.comment.push(newComment);
        pendingRemovedActivity.own_reactions.comment.push(newComment);

        queryClient.setQueryData(
          [currentFeedQueryKey],
          (feed: FeedAPIResponse) =>
            updateFeedActivity({
              activity,
              feed,
              newData: pendingRemovedActivity,
              queryKey: currentFeedQueryKey,
            })
        );
        alertCallback({
          message: "Comment successfully created.",
          type: "success",
        });
      },
      onError: (_err, _variables, context) => {
        alertCallback({
          message: "Failed to create comment. Try again.",
          type: "error",
        });
        queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
      },
    }
  );

  const { mutateAsync: updateComment, isLoading: updateCommentLoading } =
    useMutation({
      mutationFn: async (input: CommentInput) => {
        let attachment: FeedCommentUpdateInput["attachment"] | undefined;
        if (input?.mediaFile) {
          if (input?.mediaFile instanceof File) {
            attachment = await uploadFile(input.mediaFile);
          } else {
            attachment = {
              url: input.mediaFile,
              mediaType: input.mediaType,
            };
          }
        }

        await feedCommentUpdate({
          variables: {
            input: {
              commentId: comment.id,
              markdown: input.markdown,
              attachment,
              mentionedUserIds: parseMentions(input.markdown),
            },
          },
        });
      },
      onMutate: async (input: CommentInput) => {
        await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
        const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

        const updatedComment = {
          ...comment,
          data: {
            ...comment.data,
            body: input.markdown,
            mediaUrl: input.mediaFile,
            mediaType: input.mediaType,
          },
          updated_at: new Date().toISOString(),
        };

        const newData = {
          ...activity,
          latest_reactions: {
            ...activity.latest_reactions,
            comment: activity.latest_reactions.comment.map((c) =>
              c.id === comment.id ? updatedComment : c
            ),
          },
          own_reactions: {
            ...activity.own_reactions,
            comment: activity.own_reactions.comment.map((c) =>
              c.id === comment.id ? updatedComment : c
            ),
          },
        };

        queryClient.setQueryData(
          [currentFeedQueryKey],
          (feed: FeedAPIResponse) =>
            updateFeedActivity({
              feed,
              activity,
              newData,
              queryKey: currentFeedQueryKey,
            })
        );

        alertCallback({
          message: "Comment successfully updated.",
          type: "success",
        });

        return { prevFeed };
      },
      onError: (_err, _variables, context) => {
        queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
        alertCallback({
          message: "Failed to update comment. Try again.",
          type: "error",
        });
      },
    });

  const { mutate: addReaction } = useMutation({
    mutationFn: async (kind: CcoReaction) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

      const updatedActivity = cloneDeep(activity);
      const updatedComment = cloneDeep(comment);
      const { latest_children, children_counts } = updatedComment;

      const myReaction = findMyReaction({
        activity: updatedComment,
        myId: me.id,
      });

      // If myReaction exists, delete it from latest_children
      if (myReaction) {
        latest_children[myReaction.kind] = latest_children[
          myReaction.kind
        ].filter((reaction) => reaction.id !== myReaction.id);
      }

      // Add my new reaction to latest_children
      const pendingReaction = getReactionStub({
        author: me,
        kind,
      });
      latest_children[kind] = [
        ...(latest_children[kind] || []),
        pendingReaction,
      ];

      // If my reaction exists remove my reaction from children_counts
      if (myReaction) children_counts[myReaction.kind] -= 1;

      //  Add my new reaction to children_counts
      children_counts[kind] = children_counts[kind]
        ? children_counts[kind] + 1
        : 1;

      // Find the comment in updatedActivity and replace it with updatedComment
      const commentIndex = updatedActivity.latest_reactions.comment.findIndex(
        (updatedActivityComment) =>
          updatedActivityComment.id === updatedComment.id
      );

      updatedActivity.latest_reactions.comment[commentIndex] = updatedComment;

      queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
        return updateFeedActivity({
          feed,
          activity,
          newData: updatedActivity,
          queryKey: currentFeedQueryKey,
        });
      });

      if (myReaction) await streamClient?.reactions?.delete(myReaction.id);
      const newReaction = await streamClient.reactions.addChild(
        kind,
        comment,
        {
          type: kind,
          activityType: "comment",
          redirectUrl: `/activities/${activity.id}`,
        },
        {
          targetFeeds:
            comment.user_id !== me.id
              ? [`${NOTIFICATION_FEED_GROUP}:${comment.user_id}`]
              : [],
          targetFeedsExtraData: {
            parentId: comment.id,
          },
        }
      );

      return { newReaction };
    },
    onSuccess: ({ newReaction }) => {
      queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
        const pendingRemovedComment = removePendingReactions(comment);
        pendingRemovedComment.latest_children[newReaction.kind].push(
          newReaction
        );

        const newData = {
          ...activity,
          latest_reactions: {
            ...activity.latest_reactions,
            comment: activity.latest_reactions.comment.map((c) => {
              if (c.id === comment.id) return pendingRemovedComment;
              return c;
            }),
          },
        };

        return updateFeedActivity({
          activity,
          feed,
          newData,
          queryKey: currentFeedQueryKey,
        });
      });
    },

    onError: () => {
      // @todo add error handling
    },
  });

  const { mutate: removeReaction } = useMutation({
    mutationFn: async () => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

      const updatedActivity = cloneDeep(activity);
      const updatedComment = cloneDeep(comment);
      const { latest_children, children_counts } = updatedComment;

      const myReaction = findMyReaction({
        activity: updatedComment,
        myId: me.id,
      });

      if (myReaction) {
        // If myReaction exists, delete it from latest_children
        latest_children[myReaction.kind] = latest_children[
          myReaction.kind
        ].filter((reaction) => reaction.id !== myReaction.id);

        // Remove my reaction from children_counts
        children_counts[myReaction.kind] -= 1;

        // Find the comment in updatedActivity and replace it with updatedComment
        const commentIndex = updatedActivity.latest_reactions.comment.findIndex(
          (updatedActivityComment) =>
            updatedActivityComment.id === updatedComment.id
        );
        updatedActivity.latest_reactions.comment[commentIndex] = updatedComment;

        queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
          return updateFeedActivity({
            activity,
            feed,
            newData: updatedActivity,
            queryKey: currentFeedQueryKey,
          });
        });

        await streamClient?.reactions?.delete(myReaction.id);
      }
    },
    onError: () => {
      // @todo add error handling
    },
  });

  const { isLoading: loadingMoreReplies, mutate: loadMoreReplies } =
    useMutation({
      mutationKey: [currentFeedQueryKey],
      mutationFn: async () => {
        await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

        const lastReplyId = comment.latest_children.reply?.[0]?.id;

        const numberOfUnloadedReplies =
          comment.children_counts.reply - comment.latest_children.reply.length;
        const numberOfRepliesToLoad = Math.min(numberOfUnloadedReplies, 10);

        const { results } = await streamClient.reactions.filter({
          id_lt: lastReplyId,
          kind: "reply",
          limit: numberOfRepliesToLoad,
          reaction_id: comment.id,
        });

        queryClient.setQueryData([currentFeedQueryKey], (feed) => {
          return updateFeedActivity({
            feed,
            activity,
            newData: {
              ...activity,
              latest_reactions: {
                ...activity.latest_reactions,
                comment: activity.latest_reactions.comment.map((c) => {
                  if (c.id === comment.id) {
                    return {
                      ...c,
                      latest_children: {
                        ...c.latest_children,
                        reply: [...results, ...c.latest_children.reply],
                      },
                    };
                  }
                  return c;
                }),
              },
            },
            queryKey: currentFeedQueryKey,
          });
        });
      },
      onError: () => {
        // @todo add error handling
      },
    });

  return {
    addComment,
    addCommentLoading,
    updateComment,
    updateCommentLoading,
    addReaction,
    loadingMoreReplies,
    loadMoreReplies,
    removeReaction,
  };
}

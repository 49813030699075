/* eslint-disable camelcase */
import { useMutation as useApolloMutation } from "@apollo/client";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { FeedAPIResponse } from "getstream";
import { useContext } from "react";

import {
  FEED_POLL_CREATE,
  FEED_POLL_DELETE,
  FEED_POLL_UPDATE,
  FEED_POLL_VOTE_CREATE,
  FEED_POLL_VOTE_DELETE,
  FEED_POLL_VOTE_UPDATE,
} from "graphql/polls/mutations";
import useMe from "hooks/useMe";
import { StreamContext } from "lib/motorcade/providers/stream";

import {
  AlertCallback,
  Audience,
  FeedActivity,
  PollCollection,
} from "lib/motorcade/types";
import {
  prependFeedActivities,
  updateFeedActivity,
} from "lib/motorcade/utils/state";
import {
  getPollActivityStub,
  getVoteReactionStub,
} from "lib/motorcade/utils/stubs";

// @todo Generate types from graph schema instead of manually creating them
export type FeedPollCreateInput = {
  audience: Audience;
  postText: string;
  question: string;
  options: string[];
  closeAt?: string;
  group?: {
    id: string;
  };
};

export type FeedPollUpdateInput = {
  deleted: boolean;
  postText: string;
};

export default function usePoll(
  activity: FeedActivity,
  alertCallback: AlertCallback
) {
  const me = useMe();
  const { streamClient, currentFeedQueryKey } = useContext(StreamContext);
  const queryClient = useQueryClient();

  const [feedPollCreate] = useApolloMutation(FEED_POLL_CREATE);
  const [feedPollDelete] = useApolloMutation(FEED_POLL_DELETE);
  const [feedPollUpdate] = useApolloMutation(FEED_POLL_UPDATE);
  const [pollVoteCreate] = useApolloMutation(FEED_POLL_VOTE_CREATE);
  const [pollVoteDelete] = useApolloMutation(FEED_POLL_VOTE_DELETE);
  const [pollVoteUpdate] = useApolloMutation(FEED_POLL_VOTE_UPDATE);

  async function streamAddVote(choice) {
    return streamClient?.reactions?.add("vote", activity.id, {
      choices: [choice],
    });
  }

  const { mutateAsync: addPoll, isLoading: addPollLoading } = useMutation({
    mutationFn: async (input: FeedPollCreateInput) => {
      const newActivity = await feedPollCreate({
        variables: {
          input: {
            type: "SINGLE",
            audience: input.audience.toUpperCase(),
            postText: input.postText,
            question: input.question,
            options: input.options,
            closeAt: input.closeAt,
            groupId: input?.group?.id,
          },
        },
      });

      return { newActivity };
    },
    onMutate: async (input: FeedPollCreateInput) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      const pendingActivity = getPollActivityStub({ author: me, input });

      queryClient.setQueryData(
        [currentFeedQueryKey],
        (prevData: FeedAPIResponse) => {
          return prependFeedActivities({
            feedData: prevData,
            activities: [pendingActivity],
          });
        }
      );

      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Post successfully created.",
        type: "success",
      });
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
      alertCallback({
        message: "Failed to create post. Try again.",
        type: "error",
      });
    },
  });

  const addVote = useMutation({
    mutationFn: async (choice) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

      const updatedActivity = { ...activity };

      // Get myVote if it exists
      const myVote = updatedActivity.own_reactions.vote?.[0];

      // If myVote exists, delete it from latest_reactions
      if (myVote) {
        updatedActivity.latest_reactions.vote =
          updatedActivity.latest_reactions.vote.filter(
            (vote) => vote.user.id !== me.id
          );
      }

      // Add my new vote to latest_reactions
      const pendingReaction = getVoteReactionStub({
        author: me,
        choices: [choice],
      });

      updatedActivity.latest_reactions.vote = [
        ...(updatedActivity.latest_reactions.vote || []),
        pendingReaction,
      ];

      // If myVote doesn't exist increase numberOfParticipants by 1
      if (!myVote) {
        updatedActivity.object.data.numberOfParticipants = String(
          Number(updatedActivity.object.data.numberOfParticipants) + 1
        );
      }

      // If myVote exists, remove my vote from results
      if (myVote) {
        const myChoiceIndex = updatedActivity.object.data.options.findIndex(
          (option) => option === myVote.data.choices[0]
        );
        updatedActivity.object.data.results[myChoiceIndex] = String(
          Number(updatedActivity.object.data.results[myChoiceIndex]) - 1
        );
      }

      // Add my new vote to results
      const myNewChoiceIndex = updatedActivity.object.data.options.findIndex(
        (option) => option === choice
      );
      updatedActivity.object.data.results[myNewChoiceIndex] = String(
        Number(updatedActivity.object.data.results[myNewChoiceIndex]) + 1
      );

      // If myVote exists, remove my vote from own_reactions
      if (myVote) delete updatedActivity.own_reactions.vote;

      // Add my new vote to own_reactions
      updatedActivity.own_reactions.vote = [pendingReaction];

      // If myVote doesn't exist, increase reaction_counts.vote by 1
      if (!myVote) {
        updatedActivity.reaction_counts.vote =
          (updatedActivity?.reaction_counts?.vote || 0) + 1;
      }

      queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
        return updateFeedActivity({
          feed,
          activity,
          newData: updatedActivity,
          queryKey: currentFeedQueryKey,
        });
      });

      // Delete my existing vote from Stream
      if (myVote) await streamClient?.reactions?.delete(myVote.id);

      const newVote = await streamAddVote(choice);

      const voteMutation = myVote ? pollVoteUpdate : pollVoteCreate;
      return { newVote, updatedActivity, voteMutation };
    },
    onSuccess: async ({ newVote, updatedActivity, voteMutation }) => {
      // Remove pending vote from latest_reactions
      const updatedVotes =
        updatedActivity.latest_reactions?.vote.filter(
          (r) => !r.id.startsWith("pending:")
        ) || [];

      queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
        return updateFeedActivity({
          feed,
          activity,
          newData: {
            own_reactions: {
              ...updatedActivity.own_reactions,
              vote: [newVote],
            },
            latest_reactions: {
              ...updatedActivity.latest_reactions,
              vote: [...updatedVotes, newVote],
            },
            object: updatedActivity.object,
          },
          queryKey: currentFeedQueryKey,
        });
      });

      await voteMutation({
        variables: {
          input: {
            pollId: activity.foreign_id,
            choices: [newVote.data.choices[0]],
          },
        },
      });
    },
    onError: () => {
      alertCallback({
        message: "Failed to add vote. Try again.",
        type: "error",
      });
    },
  });

  const removeVote = useMutation({
    mutationFn: async () => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });

      const updatedActivity = { ...activity };

      // Get my vote
      const myVote = updatedActivity.own_reactions.vote?.[0];

      // Remove my vote from latest_reactions
      updatedActivity.latest_reactions.vote =
        updatedActivity.latest_reactions.vote.filter(
          (vote) => vote.user.id !== me.id
        );

      // Decrease numberOfParticipants by 1
      updatedActivity.object.data.numberOfParticipants = String(
        Number(updatedActivity.object.data.numberOfParticipants) - 1
      );

      // Remove my vote from results
      const myChoiceIndex = updatedActivity.object.data.options.findIndex(
        (option) => option === myVote.data.choices[0]
      );
      updatedActivity.object.data.results[myChoiceIndex] = String(
        Number(updatedActivity.object.data.results[myChoiceIndex]) - 1
      );

      // Remove my vote from own_reactions
      delete updatedActivity.own_reactions.vote;

      // Remove my vote from reaction_counts
      updatedActivity.reaction_counts.vote -= 1;

      queryClient.setQueryData([currentFeedQueryKey], (feed: unknown) => {
        return updateFeedActivity({
          feed,
          activity,
          newData: updatedActivity,
          queryKey: currentFeedQueryKey,
        });
      });

      // Delete my vote from Stream
      await streamClient?.reactions?.delete(myVote.id);

      return { voteId: myVote.id };
    },
    onSuccess: async ({ voteId }) => {
      await pollVoteDelete({
        variables: {
          input: {
            pollId: activity.foreign_id,
            voteId,
          },
        },
      });
    },
    onError: () => {
      alertCallback({
        message: "Failed to remove vote. Try again.",
        type: "error",
      });
    },
  });

  const { mutate: updatePoll } = useMutation({
    mutationFn: async (input: FeedPollUpdateInput) => {
      const updatePollMutation = input.deleted
        ? feedPollDelete
        : feedPollUpdate;

      const updatedPoll = await updatePollMutation({
        variables: {
          input: {
            pollId: activity.foreign_id,
            postText: input.postText,
          },
        },
      });

      return { updatedPoll };
    },
    onMutate: async (input: FeedPollUpdateInput) => {
      await queryClient.cancelQueries({ queryKey: [currentFeedQueryKey] });
      const prevFeed = queryClient.getQueryData([currentFeedQueryKey]);

      const pollCollection = activity.object as PollCollection;

      const newData = {
        object: {
          data: {
            ...pollCollection.data,
            postText: input.postText,
          },
          updated_at: new Date().toISOString(),
        },
      };

      if (input.postText !== pollCollection.data.postText) {
        newData.object.data.isUpdated = true;
      }

      if (input.deleted) {
        newData.object.data.deleted = true;
      }

      queryClient.setQueryData(
        [currentFeedQueryKey],
        (feed: FeedAPIResponse) => {
          return updateFeedActivity({
            feed,
            activity,
            newData,
            queryKey: currentFeedQueryKey,
          });
        }
      );

      return { prevFeed };
    },
    onSuccess: () => {
      alertCallback({
        message: "Post successfully updated.",
        type: "success",
      });
    },
    onError: (_err, _variables, context) => {
      queryClient.setQueryData([currentFeedQueryKey], context.prevFeed);
      alertCallback({
        message: "Failed to update post. Try again.",
        type: "error",
      });
    },
  });

  return {
    addPoll,
    addPollLoading,
    addVote,
    removeVote,
    updatePoll,
  };
}
